import { AxiosInstance, AxiosResponse } from 'axios';
import ColisportApiResource from './colisport-api-resource.model';
import {
  InvoiceResponse,
  InvoiceSchemaBackend,
} from '../../components/Invoice/invoice-response.model';
import { DownloadMonthlyInvoice } from '../../models/download-monthly-invoice';

export default class InvoiceApi implements ColisportApiResource {
  readonly apiUrlPath = 'invoice';

  constructor(readonly httpClient: AxiosInstance) {}

  downloadInvoiceFile(downloadId: string): Promise<AxiosResponse<InvoiceResponse>> {
    return this.httpClient.get(`${this.apiUrlPath}/getinvoicedata/${downloadId}`);
  }

  findByColisportId(colisportId: string): Promise<AxiosResponse<InvoiceSchemaBackend>> {
    return this.httpClient.get(`${this.apiUrlPath}/colisportId/${colisportId}`);
  }

  downloadMonthlyInvoices(
    downloadMonthlyInvoice: DownloadMonthlyInvoice,
  ): Promise<AxiosResponse<ArrayBuffer>> {
    return this.httpClient.post(
      `${this.apiUrlPath}/getmonthlyinvoicedata`,
      downloadMonthlyInvoice,
      {
        responseType: 'arraybuffer',
      },
    );
  }
}
