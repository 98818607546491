import { AxiosInstance, AxiosResponse } from 'axios';
import {
  RecordShipmentResponse,
  RecordShipment,
  CalculateFinalPrice,
  CalculateFinalPriceResponse,
} from '../../models/record-shipment-response';
import { RecordShipmentRequest } from '../../models/record-shipment-request.model';
import ColisportApiResource from './colisport-api-resource.model';
import {
  CalculateAssuranceFinalPrice,
  CalculateFinalAssurancePriceResponse,
  CalculatePackagesAssurancePrice,
  Shipment,
  ValidationStatus,
} from '../../models/shipment.model';
import { FilterShipment } from '../../models/filter-shipment.model';
import { CountryNotSupported } from '../../models/country-not-supported.model';

export default class ShipmentApi implements ColisportApiResource {
  readonly apiUrlPath = 'shipment';

  constructor(readonly httpClient: AxiosInstance) {}

  async downloadPrintingLabels(colisportID: string): Promise<AxiosResponse<ArrayBuffer>> {
    return this.httpClient.get(`${this.apiUrlPath}/printing-labels/${colisportID}`, {
      responseType: 'arraybuffer',
    });
  }

  async record(
    recordShipmentRequest: RecordShipmentRequest,
  ): Promise<AxiosResponse<RecordShipmentResponse>> {
    return this.httpClient.post(`${this.apiUrlPath}/record`, recordShipmentRequest);
  }

  async validate(
    colisportId: string,
  ): Promise<AxiosResponse<{ trackingNumber: string; validationStatus: ValidationStatus }>> {
    return this.httpClient.post(`${this.apiUrlPath}/validate/${colisportId}`);
  }

  getShipmentsByEmail(email: string): Promise<AxiosResponse<RecordShipment[]>> {
    return this.httpClient.get(`${this.apiUrlPath}/orders/${email}`);
  }

  getShipmentByColisportID(id: string): Promise<AxiosResponse<Shipment>> {
    return this.httpClient.get(`${this.apiUrlPath}/${id}`);
  }

  deleteShipmentByColisportID(
    colisportID: string,
  ): Promise<AxiosResponse<{ deletedCount: number }>> {
    return this.httpClient.delete(`${this.apiUrlPath}/delete/${colisportID}`);
  }

  update(
    colisportID: string,
    data: Partial<Shipment>,
  ): Promise<AxiosResponse<{ deletedCount: number }>> {
    return this.httpClient.put(`${this.apiUrlPath}/update/${colisportID}`, data);
  }

  async getShipmentsBackOffice(query?: string): Promise<AxiosResponse<FilterShipment>> {
    return this.httpClient.get(`${this.apiUrlPath}/filters${query !== '' ? `?${query}` : ''}`, {
      params: `filters?${query}`,
    });
  }

  async revolutPaidCheck(id: string): Promise<AxiosResponse<boolean>> {
    return this.httpClient.get(`${this.apiUrlPath}/isPaidViaRevolut/${id}`);
  }

  async billingInfoCheck(id: string): Promise<AxiosResponse<boolean>> {
    return this.httpClient.get(`${this.apiUrlPath}/hasBillingInfo/${id}`);
  }

  async getVatDetails(
    startDate: Date,
    endDate: Date,
    timezone: string,
  ): Promise<AxiosResponse<Blob>> {
    return this.httpClient.post(
      `${this.apiUrlPath}/vatDetails`,
      { startDate, endDate, timezone },
      {
        responseType: 'blob',
      },
    );
  }

  async getFinalPrice(
    calculateFinalPrice: CalculateFinalPrice,
  ): Promise<AxiosResponse<CalculateFinalPriceResponse>> {
    return this.httpClient.post(`${this.apiUrlPath}/finalPrice`, {
      isIndividualAccountType: calculateFinalPrice.isIndividualAccountType,
      assuranceAnnulationValue: calculateFinalPrice.assuranceAnnulationValue,
      packagesAssuranceValues: calculateFinalPrice.packagesAssuranceValues,
      offerPriceWithVAT: calculateFinalPrice.offerPriceWithVAT,
    });
  }

  async getPackagesAssurancePrice(
    calculatePackagesAssurancePrice: CalculatePackagesAssurancePrice,
  ): Promise<AxiosResponse<number[]>> {
    return this.httpClient.post(`${this.apiUrlPath}/packagesAssurancePrice`, {
      isIndividualAccountType: calculatePackagesAssurancePrice.isIndividualAccountType,
      packagesAssuranceValues: calculatePackagesAssurancePrice.packagesAssuranceValues,
    });
  }

  async getAssuranceFinalPrices(
    calculateAssuranceFinalPrice: CalculateAssuranceFinalPrice,
  ): Promise<AxiosResponse<CalculateFinalAssurancePriceResponse>> {
    return this.httpClient.post(`${this.apiUrlPath}/finalAssurancePrices`, {
      isIndividualAccountType: calculateAssuranceFinalPrice.isIndividualAccountType,
      hasAssuranceAnnulation: calculateAssuranceFinalPrice.hasAssuranceAnnulation,
      packagesAssuranceValues: calculateAssuranceFinalPrice.packagesAssuranceValues,
    });
  }

  async calculateInternational(data: CountryNotSupported): Promise<AxiosResponse> {
    return this.httpClient.post(`${this.apiUrlPath}/calculateInternational`, data);
  }
}
